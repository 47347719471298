<template>
  <div class="execution">
      <el-alert :title='$t("adminRoute.tit")' type="warning" />
      <vue-json-editor v-model="json" :show-btns="false" />
      <div align="center">
        <el-button @click="edit()">{{$t("button.newer")}}</el-button>
      </div>
  </div>
</template>
<script setup>
import { ref,getCurrentInstance } from 'vue'
import vueJsonEditor from "json-editor-vue3";
import Api_ from '@/api/index'
import {ElNotification } from 'element-plus'
const { $t } = getCurrentInstance().proxy;

const json = ref(null)

const getList = ()=>{
    Api_.fetchListRoute().then(response => {
        const result = response.data;
        for (var i = 0; i < result.length; i++) {
            const route = result[i];
            if (route.predicates) {
                const predicates = route.predicates;
                route.predicates = JSON.parse(predicates);
            }
            if (route.filters) {
                const filters = route.filters;
                route.filters = JSON.parse(filters);
            }
        }
        json.value = result;
    });
}
const edit = ()=>{
    Api_.putObjRoute(json.value).then(() => {
        Api_.refreshObjRoute().then(() => {
            ElNotification({
                title: $t("tyle_.sycc"),
                message: $t("alert.succSync"),
                type: 'success',
            })
        });
    });
}
getList()
</script>

<style lang="scss">
    .execution{
        height: calc(100vh - 80px);
        overflow: auto;
    }
</style>